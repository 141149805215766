<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-card
          class="mb-0"
        >

          <b-list-group>
            <b-list-group-item
              v-for="(item,index) in categoryData"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >  <b-link
              class="alert-link"
              @click="fetchTrainings(item.id)"
            >
              {{ item.name }}
            </b-link>

            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col
            v-for="(item,index) in trainingData"

            :key="index"
            md="6"
            lg="4"
          >
            <b-card
              :header="'Eğitim Bitiş Tarihi: '+$format_date(item.end_Date)"
              :title="item.name"
              :img-src="item.image_url"
              class="text-center"
              footer-tag="footer"
            >
              <b-card-text>
                {{ item.description }}
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :to="{ name: 'training-detail', params: { id: item.id } }"
              >
                {{ $t('Eğitime Git') }}
              </b-button>

              <template #footer>
                <b-badge :variant="getTrainingStatusName(item).variant">
                  {{ getTrainingStatusName(item).text }}
                </b-badge>
                <div v-if="item.training_history!=null">
                  <label>{{ $t('Tamamlama Oranım') }}</label>
                  <b-progress
                    :max="100"
                  >
                    <b-progress-bar :value="item.history.complete_stats.complete_rate">
                      <strong>{{ item.history.complete_stats.answer_count }} / {{ item.history.complete_stats.question_count }}</strong>
                    </b-progress-bar>
                  </b-progress>
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <component
      :is="trainingData === undefined ? 'div' : 'b-card'"
      v-if="trainingData==null"
    >

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="trainingData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching training data
        </h4>
        <div class="alert-body">
          No user found with this user id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'apps-users-list'}"
          >
            User List
          </b-link>
          for other users.
        </div>
      </b-alert>
      <!-- Alert: No item found -->
      <b-alert

        variant="warning"
        :show="trainingData==null"
      >
        <h4 class="alert-heading">
          {{ $t('Eğitim Yok') }}
        </h4>
        <div class="alert-body">
          {{ $t('Üzerinize atanmış herhangibir eğitim mevcut değil.') }}
        </div>
      </b-alert>
    </component>

  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BCardText, BCol, BRow, BProgress, BProgressBar, BButton, BBadge, BListGroup,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import trainingStoreModule from '../trainingStoreModule'

export default {
  components: {
    BTab,
    BListGroup,
    BTabs,
    BProgress,
    BProgressBar,
    BButton,
    BBadge,
    BCard,
    BAlert,
    BLink,
    BCardText,
    router,
    BCol,
    BRow,
  },
  methods: {
    getTrainingStatusName(item) {
      const statusData = {
        variant: 'primary',
        text: '',
        startable: true,
      }
      const st = item.startable_status
      if (st.last_history === null && st.startable) {
        statusData.startable = true
        statusData.variant = 'primary'
        statusData.text = 'Eğitime Başlanmadı'
      } else if (st.continius_history != null && st.startable) {
        statusData.startable = true
        statusData.variant = 'warning'
        statusData.text = 'Eğitim Sınavınız Devam Ediyor. Süreniz Bitmeden Tamamlayınız'
      } else if (st.last_history != null && st.startable === false) {
        statusData.startable = false
        statusData.variant = 'secondary'
        statusData.text = `Eğitim Sınavına ${this.$format_datetime(st.test_next_start_date)} Tarihinde Tekrar Girebilirsiniz.`
      } else if (st.last_history != null && st.startable) {
        statusData.startable = false
        statusData.variant = 'danger'
        statusData.text = 'Eğitim Sınavına Tekrar Giriniz'
      } else if (st.is_success) {
        statusData.startable = false
        statusData.variant = 'success'
        statusData.text = 'Eğitimi Başarıyla Tamamladınız.'
      }
      return statusData
    },
  },
  setup() {
    const trainingData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-training'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, trainingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })
    const categoryData = ref(null)
    store
      .dispatch('app-training/fetchTrainingCategories', {
        list_type: 'all',
      })
      .then(response => {
        const { data } = response.data

        categoryData.value = data
      })
      .catch(() => {
      })

    const fetchTrainings = categoryId => {
      store.dispatch('app-training/fetchMyTrainings', { id: router.currentRoute.params.id, category_id: categoryId })
        .then(response => {
          trainingData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            trainingData.value = undefined
          }
        })
    }
    fetchTrainings(0)
    return {
      categoryData,
      trainingData,
      fetchTrainings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
